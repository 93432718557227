<template>
  <div class="book_slider">
    <p class="mb-0">
      <b-link :to="titleLink" v-if="titleLink">{{title}}</b-link>
      <strong v-else>{{title}}</strong>
    </p>
    <div class="slider">
      <div ref="scrolldiv" class="scroll" @scroll="toggleScrollArrows">
        <div class="item" v-for="book in books" :key="book.id">
          <b-link :to="{name: 'book-preview', params: {book_id: book.id}}">
            <b-img :src="coverImage(book)" :alt="book.title" :title="book.title" @load="toggleScrollArrows" />
          </b-link>
          <BookFavoriteButton class="favorite_btn ml-2" :book_id=book.id v-if="isLoggedIn" />
        </div>
      </div>
      <span class="arrow_bg left bg-light text-dark" @click="sliderScroll(-1)" v-show="showLeftArrow"><i class="arrow" /></span>
      <span class="arrow_bg right bg-light text-dark" @click="sliderScroll(+1)" v-show="showRightArrow"><i class="arrow" /></span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BookFavoriteButton from '@/views/public/book/components/BookFavoriteButton.vue';

// Scroll distance (in pixel) on each click on scroll button
const scrollStep = 100;

export default {
  name: 'BookSlider',
  components: { BookFavoriteButton },
  props: {
    title: { type: String, required: true },
    titleLink: { type: Object, default: null },
    books: {
      type: Array,
      default() { return []; },
    },
  },

  data() {
    return {
      showLeftArrow: false,
      showRightArrow: false,
    };
  },

  computed: {
    ...mapState('auth', ['isLoggedIn']),
  },

  methods: {
    coverImage(book) {
      return (book.coverimage && book.coverimage.length) ? book.coverimage[0] : '';
    },
    sliderScroll(dir) {
      const { scrolldiv } = this.$refs;
      if (scrolldiv) scrolldiv.scrollLeft += scrollStep * (+dir);
    },
    toggleScrollArrows() {
      const { scrolldiv } = this.$refs;
      if (scrolldiv) {
        this.showLeftArrow = (scrolldiv.scrollLeft > 0);
        this.showRightArrow = (scrolldiv.scrollLeft < (scrolldiv.scrollWidth - scrolldiv.offsetWidth));
      }
    },
  },

};

</script>

<style lang="stylus" scoped>

.book_slider .slider
  position relative
  width 100%
  .scroll
    display inline-block
    max-width 100%
    padding-right 2rem
    white-space nowrap
    overflow-x scroll
    -ms-overflow-style none
    scrollbar-width none
    &::-webkit-scrollbar
      display none
    .item
      position relative
      display inline-block
      padding 0.5rem 0.5rem 0.5rem 0
      .favorite_btn
        z-index 1
        position absolute
        bottom 0.2rem
        right 1rem
        transform translateY(-50%)
        opacity 0.7
      img
        object-fit cover
        height 10rem
  .arrow_bg
    z-index 2
    position absolute
    top 50%
    transform translateY(-50%)
    width 3rem
    height 3rem
    padding 0.8rem
    border-radius 50%
    opacity 0.7
    &:hover
      opacity 1
      cursor pointer
    .arrow
      display inline-block
      padding 0.5rem
      border-width 0 0.3rem 0.3rem 0
      border-style solid
    &.left
      left 0
      .arrow
        transform rotate(135deg)
    &.right
      right 0
      .arrow
        transform rotate(-45deg)

</style>
