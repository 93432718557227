<template>
  <button class="btn btn-sm" :class="{'rounded-pill':!button, 'btn-primary':isFavorite, 'btn-outline-primary':!isFavorite}" @click="toggleAsFavorite" :disabled="loading"
    :title="(isFavorite ? 'Remove this book from your favorite list' : 'Add this book to your favorite list')" v-if="isLoggedIn">
    <b-spinner small v-if="loading" />
    <FillStarIcon v-else-if="isFavorite" />
    <StarIcon v-else />
    <template v-if="button"> {{isFavorite ? 'Remove from Favorite' : 'Mark as Favorite'}}</template>
  </button>
</template>

<script>
import StarIcon from 'mdi-vue/StarOutline';
import FillStarIcon from 'mdi-vue/Star';
import { mapState } from 'vuex';

export default {
  name: 'BookFavoriteButton',
  components: { StarIcon, FillStarIcon },
  props: {
    book_id: { type: String, required: true },
    button: { type: Boolean, default: false },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState('auth', ['isLoggedIn']),
    ...mapState('book', ['favoriteBooks']),
    isFavorite() {
      return this.favoriteBooks.findIndex(x => x.id === this.book_id) >= 0;
    },
  },

  methods: {
    toggleAsFavorite() {
      const url = this.isFavorite ? 'book/removeAsFavorite' : 'book/markAsFavorite';
      this.loading = true;
      this.$store.dispatch(url, [this.book_id]).catch(() => {
        this.$toast.error('Unable to update the Favorites List at this time. Try again later.');
      }).finally(() => {
        this.loading = false;
      });
    },
  },

};
</script>
